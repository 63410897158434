/*=====================
   Utility
=======================*/

.underline {
    border-bottom: 1px solid black;
}

.noShadow {
    box-shadow: none;
}

.rounded {
    border-radius: 999px !important;
}

.text-reset {
    font: inherit !important;
    color: inherit !important;
    letter-spacing: inherit !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.hidden {
    position: fixed !important;
    opacity: 0 !important;
    cursor: default !important;
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
    pointer-events: none !important;
}

.hide {
    opacity: 0 !important;
    pointer-events: none;
}

.show {
    opacity: 1 !important;
    pointer-events: auto;
}

.no-pointer {
    pointer-events: none;
}

/*      Align     */

.absolute-center {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.absolute-horizontal {
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
}

.absolute-vertical {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
}

/*      Cursor     */

.c-auto {
    cursor: auto;
}

.c-help {
    cursor: help;
}

.c-wait {
    cursor: wait;
}

.c-cross {
    cursor: crosshair;
}

.c-disabled {
    cursor: not-allowed !important;
    pointer-events: unset !important;
}

.c-zoomIn {
    cursor: zoom-in;
}

.c-grab {
    cursor: grab;
}

.c-pointer {
    cursor: pointer;
}

/*      z index     */

.z-top {
    z-index: 1600 !important;
}
