/*=====================
    Global Setting
=======================*/

a,
p,
button,
input,
div,
span,
td,
textarea {
    border: none;
    text-decoration: none;

    &:focus {
        outline: none !important;
    }
}

body {
    background-color: #f9f8f6 !important;
    color: #212121 !important;
}

/*=====================
  Library
=======================*/

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

/*=====================
  Custom Class
=======================*/

@import 'assets/scss/utility';
